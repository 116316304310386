.hoverBgDarkUl {
    a {
        color: #323232;
    }
    li:hover, a:hover, button:hover {
        background-color: #aaaaaa;
        color: white;
    }
}

.swal2-modal {
    padding-top: 1.25em;
    // :not(.swal2-icon) {
    //     border: none;
    // }
}

.swal2-icon {
    margin: 0 auto; /* 將左右邊距設為auto，使圖示在彈出框中水平置中 */
}

// .modal-dialog {
//     overflow-y: auto; /* 允許模態對話框在需要時滾動 */
// }

.banner {
    height: calc(90vh + 60px);
}

.smBanner {
    display: flex;
    @media screen and (min-width: 600px) {
        display: none;
    }
}
.mdBanner {
    display: none;
    @media screen and (min-width: 600px) {
        display: flex;
    }
}

.buttons {
    justify-content: center;
}

.icons {
    @media screen and (max-width: 530px) and (max-width: 650px) {
        bottom: 10%
    }
}

.pageH2 {
    letter-spacing: 4px;
    top: 30%;
    font-size: 2.5rem;
    font-weight: 700;
    @include md {
        font-size: 3.5rem;
        top: 35%;
    }
    @include lg {
        font-size: 4rem;
    }
    @include xl {
        top: 40%;
    }
}

.hover-bg-dark {
    &:hover {
        background-color: #a0a0a0;
        * {
            @extend .text-white;
        }
    }
}