.about-us-logo1-1 {
    width: 130%;
    max-width: unset;
}

.about-us-sponsor10 {
    width: 130%;
    max-width: unset;
    @include xl {
        width: 120% !important;
    }
}