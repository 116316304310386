.loading-spinner {
    border: 3px solid #f3f3f3; /* 灰色的圓圈 */
    border-top: 3px solid rgb(220, 53, 69); /* 上方的藍色，可自行調整顏色 */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite; /* 使用spin動畫 */
}
@keyframes spin {
    0% {
      transform: rotate(0deg); /* 起始角度 */
    }
    100% {
      transform: rotate(360deg); /* 結束角度 */
    }
}