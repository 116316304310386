.agenda-h2 {
    @extend .pageH2;
    font-size: 2rem;
    @include sm {
        font-size: 2.5rem;
    }
    @include md {
        font-size: 3.5rem;
        top: 35%;
        letter-spacing: 8px;
    }
    @include lg {
        font-size: 4rem;
    }
    @include xl {
        top: 40%;
    }
}