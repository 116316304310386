p, div, ul, ol, h1, h2, h3, h4, h5, h6 {
    margin: 0;
    line-height: 1.5;
}

a {
    text-decoration: none;
    color: gray;
}

select {
    background-color: transparent;
}

a, button {
    border-radius: 8px;
    border-width: 2px;
}

input {
    line-height: 2;
    border-radius: 4px;
    border: 1px solid #cccccc;
}

label {
    display: block;
    margin-bottom: 4px;
}

* {
    word-break: break-word;
}