.poster_form_loading-text {
    white-space: nowrap;
}

.poster_form_ellipsis {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ellipsis 1.25s infinite;
}

@keyframes ellipsis {
    0% {
      width: 0;
    }
    25% {
      width: 0.5em;
    }
    50% {
      width: 1em;
    }
    75% {
      width: 0.5em;
    }
    100% {
      width: 0;
    }
}