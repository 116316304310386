.footer-html-container {
    >* {
        width: 100% !important;
        text-align: start !important;
        color: #2b2829 !important;
    }
    @include sm {
        padding-left: 32px;
    }
    @include md {
        padding-left: 0;
    }
}